<template>
  <div class="main">
    <div class="headNav clearfix">
      <div class="left">
        <div class="home pulll_left" style="margin-left: 20px">
          <router-link to="/nav"><i class="el-icon-house"></i>首页</router-link>
        </div>
        <div class="menu menu1 pulll_right " style="margin-left: 10px">
          <ul>
            <li><a href="#">
              <router-link to="/equipment">{{undefinedName}}</router-link>
            </a></li>
            <!--          <li><a href="#">功能二</a></li>-->
          </ul>
        </div>
      </div>
      <div class="name" >
        <div class="" style="font-size: 32px; font-weight: 500;line-height: 60px">{{ userName }}</div>
<!--        <div class="" style="font-size: 20px; font-weight: 500; line-height: 20px;margin-bottom: 5px">{{this.$store.state.dev_name}} </div>-->
        <div class="" style="font-size: 20px; font-weight: 500; line-height: 20px;margin-bottom: 5px">{{ subtitle }}</div>
      </div>
      <div class="right">
        <div class="menu menu2 pulll_left" style="width:40%">
          <ul>
            <!--        <li style="margin-left: 0"><a href="#">功能三</a></li>-->
            <li >
              <el-button type="text" @click="logout" style="color: #FFFFFF;font-size: 22px;
  font-family: SimHei, serif;"><i class="el-icon-switch-button"></i>退出
              </el-button>
<!--              <router-link to="/login"><i class="el-icon-switch-button"></i>退出</router-link>-->
            </li>
          </ul>
        </div>
        <div class="time pulll_left" id="showTime"
             style="width: 60%;padding: 0;font-size: 22px;height: 60px;line-height: 85px;text-align: left">{{ nowTime }}
        </div>
        <div style="line-height: 20px;margin-top: 5px;text-align: left;font-size: 20px"> 已安全运行 {{day}} 天</div>
      </div>
    </div>
    <!--    数据页面-->
    <router-view/>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'navbar',
  data () {
    return {
      undefinedName: '用户管理',
      timer: '', // 定义一个定时器
      nowTime: '',
      userName: window.localStorage.userName,
      subtitle: window.localStorage.subtitle,
      day: window.localStorage.dateTotal
    }
  },
  beforeMount () {
    this.getTime()
    // this.day = this.$store.state.dateTotal
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.checkName()
    }
  },
  methods: {
    // 名称
    checkName () {
      // console.log('window.localStorage.confirmIndex',this.$store.alert.confirmIndex)
      if (this.$store.state.confirmIndex === 4) {
        this.undefinedName = '设备管理'
      } else {
        this.undefinedName = '用户管理'
      }
    },
    // 退出登录
    logout () {
      window.localStorage.clear()// 销毁token
      this.$router.push('/login')
      this.$apiFun.postLogout({
        code: this.$store.state.customerCode,
        token: this.$store.state.token,
        userId: this.$store.state.userId
      }).then(res => {
        console.log('成功退出')
      })
    },
    getTime () {
      this.timer = setInterval(() => {
        const timeDate = new Date()
        const year = timeDate.getFullYear()
        const mounth = timeDate.getMonth() + 1
        const day = timeDate.getDate()
        let hours = timeDate.getHours()
        hours = hours >= 10 ? hours : '0' + hours
        let minutes = timeDate.getMinutes()
        minutes = minutes >= 10 ? minutes : '0' + minutes
        let seconds = timeDate.getSeconds()
        seconds = seconds >= 10 ? seconds : '0' + seconds
        const week = timeDate.getDay()
        const weekArr = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六'
        ]

        this.nowTime = `${year}/${mounth}/${day} ${hours}:${minutes}:${seconds}`
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, body {
  padding: 0;
  margin: 0;
}
.center {
  display: flex;
  flex-direction: column;
}
.pulll_right {
  float: right;
}

.pulll_left {
  float: left;
}

li {
  float: left;
  list-style: none;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

a:hover {
  color: #407abd;
}

.main {
  width: 100%;
  height: 920px;
  color: #fff;
  background: #033c76;

  .headNav {
    margin-top: 10px;
    width: 100%;
    height: 85px;
    line-height: 85px;
    text-align: center;
    font-weight: 400;

    .left, .right {
      width: 24%;
      float: left;
    }

    .name {
      width: 52%;
      float: left;
      text-align: center;
      color: #e8eefd;
      text-shadow: 0.5px 0.5px 1px #79b0e8;
    }

    .clearfix:after, .clearfix:before {
      display: table;
      content: " "
    }

    .clearfix:after {
      clear: both
    }

    li {
      margin-left: 20px;
      font-weight: 400;
    }
  }
}

</style>
